<template>
  <div class="sljr-render">
    <Breadcrumb></Breadcrumb>
    <!-- 一级菜单下面所拥有的二级菜单 -->
    <el-container>
      <el-aside width="228px">
        <SideMenu :items="items"></SideMenu>
      </el-aside>

      <!-- 以及二级菜单所对应的页面 -->
      <el-main class="conten-box">
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>

import SideMenu from '@/components/SideMenu/SideMenu';

export default {
  components: {
    SideMenu
  },
  data () {
    return {
      items: [
        {
          index: 'intro',
          title: '基金会介绍'
        }, {
          index: 'framework',
          title: '组织框架'
        }, {
          title: '理事会',
          index: 'council'
        }, {
          title: '基金会章程',
          index: 'rule'
        }, {
          title: '机构荣誉',
          index: 'institutionalHonor'
        }, {
          title: '联系我们',
          index: 'contactUs'
        }
      ],

    }
  },
  created () {
  },
  methods: {
  },
}
</script>

<style scoped lang='scss'>
</style>